<script>
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'

export default {
  name: 'event-card',
  components: {
    BaseDate,
    BaseIcon,
    BaseLink,
    BaseAvatar,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusIcon() {
      if (this.event.isAccepted) {
        return { bg: 'bg-emerald-700', icon: 'plain/check' }
      } else if (this.event.isDeclined) {
        return { bg: 'bg-red-700', icon: 'plain/close' }
      } else {
        return { bg: 'bg-gray-300', icon: 'rec/question-mark' }
      }
    },
  },
}
</script>

<template>
  <base-link
    class="block"
    :to="{
      name: 'rec-event',
      params: { eventId: event.id },
    }"
  >
    <div
      class="cursor-pointer rounded-xl bg-white p-[15px] text-eonx-neutral-800 duration-300 hover:bg-slate-200 md:bg-eonx-neutral-50"
    >
      <div class="flex items-center">
        <div class="relative">
          <base-avatar class="mr-[15px]" :src="event.image" size="md" />
          <div
            :class="[
              'absolute bottom-0 right-0 -mb-[5px] mr-[5px] flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-3 border-white text-white',
              statusIcon.bg,
            ]"
          >
            <base-icon :svg="statusIcon.icon" :size="15" />
          </div>
        </div>
        <div class="truncate">
          <div class="truncate font-bold leading-tight">
            {{ event.name }}
          </div>
          <div class="flex flex-wrap font-bold leading-tight">
            <base-date
              :date="event.startsAt"
              class="text-xs text-eonx-neutral-600"
              type="day-time-sfd"
            />
          </div>
        </div>
      </div>
    </div>
  </base-link>
</template>
