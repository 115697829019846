<script>
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import RenderMessage from '/~rec/components/render/render-message.vue'
import BaseClampLine from '/~/components/base/clamp-line/base-clamp-line.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { formatDate } from '/~/utils/format/date'

export default {
  name: 'celebrations-card',
  components: {
    BaseLink,
    BaseAvatar,
    BaseIcon,
    RenderMessage,
    BaseClampLine,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusIcon() {
      const type = this.post.type

      if (type === 'award') {
        return { bg: 'bg-violet-400', icon: 'rec/award-solid' }
      } else if (type === 'birthday') {
        return { bg: 'bg-pink-400', icon: 'rec/cake' }
      } else {
        return { bg: 'bg-amber-300', icon: 'rec/briefcase' }
      }
    },
    abbr() {
      const member = this.post.member

      return `${member.lastName.substr(0, 1)} ${member.firstName.substr(0, 1)}`
    },
  },
  methods: {
    formatDate,
  },
}
</script>

<template>
  <base-link
    class="block"
    :to="{
      name: 'rec-events-feed',
      params: { feedId: 'celebrations' },
      query: {
        eventId: post.id,
        date: formatDate('daymonthyearnumeric', post.date),
      },
    }"
  >
    <div
      class="flex cursor-pointer rounded-xl bg-white p-[15px] text-eonx-neutral-800 duration-300 hover:bg-slate-200 md:bg-eonx-neutral-50"
    >
      <div class="mb-2.5 flex items-center">
        <div class="relative">
          <base-avatar
            class="mr-[15px]"
            :src="post.image"
            :abbr="abbr"
            size="md"
          />
          <div
            :class="[
              'absolute bottom-0 right-0 -mb-[5px] mr-[5px] flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-3 border-white text-white',
              statusIcon.bg,
            ]"
          >
            <base-icon :svg="statusIcon.icon" :size="12" />
          </div>
        </div>
      </div>
      <base-clamp-line :rows="4">
        <render-message class="leading-normal" :content="post.name" />
      </base-clamp-line>
    </div>
  </base-link>
</template>
